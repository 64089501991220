import Head from "next/head";
import Image from "next/image";

// we'll probably change some thing swith imports/paths
import Panel from "Clutch/Atoms/Panel";
import Gutters from "Clutch/Atoms/Gutters";
import Card from "Clutch/Atoms/Card";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import Divider from "Clutch/Atoms/Divider";

import styles from "./index.module.scss";

export default function Home() {
  return (
    <Panel>
      <Gutters>
        <Card>
          <Typography size={1} fill>
            GME TO THE MOON 🚀🚀🚀🚀🚀🚀
          </Typography>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            Page Types
          </Typography>
          <Divider />
          <div>
            <TextLink
              href={
                "/Speedway-Chevy-SBC-BBC-Universal-Aluminum-Radiators,3557.html"
              }
              intent={"action"}
              size={1}
            >
              The Radiator
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/Mustang-II-IFS-Narrow-Control-Arms-Coilover-Springs-Dropped-Spindles,35677.html"
              }
              intent={"action"}
              size={1}
            >
              Kit With Index Option Selection (Mustang Kit)
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/Speedway-Single-Layer-Two-Piece-Racing-Suit-Combos,80860.html"
              }
              intent={"action"}
              size={1}
            >
              Kit Without Index Option Selection (Racing Suit)
            </TextLink>
          </div>
          <div>
            <TextLink
              href={"/AFCO-21-Series-Custom-Racing-Shock,65888.html"}
              intent={"action"}
              size={1}
            >
              Multiple Index Option Selections (Shock)
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/Intercomp-100457-Kart-Caster-Camber-Gauge-Adapter-Metric,140425.html"
              }
              intent={"action"}
              size={1}
            >
              Non-Purchaseable Product
            </TextLink>
          </div>
          <div>
            <TextLink
              href={"/67-69-Camaro-Conv-Top-Boot-w-Clips,122830.html"}
              intent={"action"}
              size={1}
            >
              Partially Non-Purchaseable Product
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            Gallery Variants
          </Typography>
          <Divider />
          <div>
            <TextLink
              href={
                "/Speedway-Chevy-SBC-BBC-Universal-Aluminum-Radiators,3557.html"
              }
              intent={"action"}
              size={1}
            >
              {"Video & Images"}
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            Summary Variants
          </Typography>
          <Divider />
          <div>
            <TextLink
              href={"/Accel-Super-Coil,39.html"}
              intent={"action"}
              size={1}
            >
              No Details or Specs
            </TextLink>
          </div>
          <div>
            <TextLink
              href={"/Impact-G1-Racing-Gloves,416.html"}
              intent={"action"}
              size={1}
            >
              No Details
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/Intercomp-100457-Kart-Caster-Camber-Gauge-Adapter-Metric,140425.html"
              }
              intent={"action"}
              size={1}
            >
              No Specs
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            Digital Asset Variants
          </Typography>
          <Divider />
          <div>
            <TextLink href={"/Fuzzy-Dice,2211.html"} intent={"action"} size={1}>
              Image and Document
            </TextLink>
          </div>
          <div>
            <TextLink
              href={"/Simpson-Suede-Leather-Hightop-Racing-Shoes,1041.html"}
              intent={"action"}
              size={1}
            >
              Sizing Chart
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            CA Variants
          </Typography>
          <Divider />
          <div>
            <TextLink
              href={
                "/Aluminum-X-Series-Heim-Joint-Rod-End-5-8-18-RH-1-2-Inch-Hole,9646.html"
              }
              intent={"action"}
              size={1}
            >
              Prop 65
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/Adjustable-Holley-2300-Rochester-2G-Two-Barrel-Carb-Adapter,1636.html"
              }
              intent={"action"}
              size={1}
            >
              CARB
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            {"Videos & Articles Variants"}
          </Typography>
          <Divider />
          <div>
            <TextLink
              href={
                "/Standard-Steel-Heim-Joint-Rod-Ends-3-4-16-LH-Male,29391.html"
              }
              intent={"action"}
              size={1}
            >
              {"No videos"}
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/Swedged-Steel-Sleeve-Tube-for-5-8-18-Thread-Rod-Ends,2092.html"
              }
              intent={"action"}
              size={1}
            >
              {"No articles or videos"}
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            {"Installation Variants"}
          </Typography>
          <Divider />
          <div>
            <TextLink
              href={"/QA1-Adjustable-14-Inch-Coil-Over-Shock-Kit,39192.html"}
              intent={"action"}
              size={1}
            >
              {"No instruction sheets"}
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/Speedway-Single-Layer-High-top-Racing-Shoes-SFI-33-5,68870.html"
              }
              intent={"action"}
              size={1}
            >
              {"No installation warnings"}
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            {"Shipping Availability Variants"}
          </Typography>
          <Divider />
          <div>
            <TextLink
              href={"/AN-Fitting-Plug-Economy-Pack,23717.html"}
              intent={"action"}
              size={1}
            >
              {"InStock"}
            </TextLink>
          </div>
          <div>
            <TextLink
              href={"/Ford-9-Inch-Bolt-On-Rear-Disc-Brake-Kit,5336.html"}
              intent={"action"}
              size={1}
            >
              {"InStock but warehouse does not have ISP"}
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <Typography size={1.5} font={"heading"}>
            Alternates
          </Typography>
          <div>
            <TextLink
              href={
                "/2-Inch-Offset-Vega-Pitman-Steering-Arm-Drilled-for-5-8-Inch-Bolt-Zinc-Finish,83801.html"
              }
              intent={"action"}
              size={1}
            >
              {"Backordered Alternates"}
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/K-N-Filters-100-8562-Sprint-Car-Air-Box-Base-for-Hilborn-Injectors,457.html"
              }
              intent={"action"}
              size={1}
            >
              {"Discontinued/OOS Alternates"}
            </TextLink>
          </div>
        </Card>
        <br />
        <Card>
          <div>
            <TextLink
              href={
                "/Mustang-II-IFS-Narrow-Control-Arms-Coilover-Springs-Dropped-Spindles,35677.html?sku=91034209-4%201/2"
              }
              intent={"action"}
              size={1}
            >
              Broken URL
            </TextLink>
          </div>
          <div>
            <TextLink
              href={
                "/Mustang-II-IFS-Narrow-Control-Arms-Coilover-Springs-Dropped-Spindles,35677.html?sku=91034209-4%201%2F2"
              }
              intent={"action"}
              size={1}
            >
              Broken URL encoded SKU (still broken)
            </TextLink>
          </div>
          <div>
            <TextLink
              href={"/mfe/pdp/product/35677/91034209-4%201%2F2"}
              intent={"action"}
              size={1}
            >
              A way to visit that page
            </TextLink>
          </div>
        </Card>

        <Head>
          <title>Create Next App</title>
          <link rel="icon" href="/favicon.ico" />
        </Head>
      </Gutters>
    </Panel>
  );
}
